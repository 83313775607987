// project imports
import MinimalLayout from 'layout/MinimalLayout';
import { lazy } from 'react';
import Loadable from 'ui-component/Loadable';
import Error from 'views/pages/maintenance/Error';

// ==============================|| AUTHENTICATION ROUTING ||============================== //
const SampleCard = Loadable(lazy(() => import('views/sample-page')));
const SurveyPreviewLoader = Loadable(lazy(() => import('views/new-preview/SurveyPreviewLoader')));
const SurveySuccessPage = Loadable(lazy(() => import('views/SurveySuccessPage')));
const Maintenance = Loadable(lazy(() => import('views/pages/maintenance/ComingSoon/ComingSoon2')));
const SurveyTemplatePreview = Loadable(lazy(() => import('views/new-preview/surveyTemplatePreview')));
const ViewQuestion = Loadable(lazy(() => import('views/preview/view')));

const OtherRoutes = {
    path: '/',
    element: <MinimalLayout />,
    children: [
        {
            path: '*',
            element: <Error />
        },
        {
            path: '/preview/:shortcode',
            element: <SurveyPreviewLoader />
        },
        {
            path: '/survey-success',
            element: <SurveySuccessPage />
        },
        {
            path: '/maintenance',
            element: <Maintenance />
        },
        {
            path: 'templates/:surveyToken/preview',
            element: <SurveyTemplatePreview />
        },
        {
            path: ':surveyToken/question-view/:qId',
            element: <ViewQuestion />
        }
    ]
};

export default OtherRoutes;
