import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import SurveyService from 'services/survey.service';
import { useParams, Link } from 'react-router-dom';
import { Box, Typography, Tabs, Tab, Paper, Container, IconButton, AppBar, Toolbar, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import DetailedReport from './detailed-report';
import Summary from './detailed-report/summary';
import ResponseSummary from './detailed-report/ResponseSummary';
import ResponseInsights from './detailed-report/ResponseInsights';
import GlobalModalHelpButton from 'views/components/GlobalModalHelpButton';

const StyledTab = styled(Tab)(({ theme }) => ({
    textTransform: 'none',
    minWidth: 0,
    fontWeight: theme.typography.fontWeightRegular,
    color: 'rgba(0, 0, 0, 0.7)',
    '&.Mui-selected': {
        color: theme.palette.primary.main,
        fontWeight: theme.typography.fontWeightMedium
    }
}));

const TabPanel = (props) => {
    const { children, value, index, ...other } = props;
    return (
        <div role="tabpanel" hidden={value !== index} id={`survey-tabpanel-${index}`} aria-labelledby={`survey-tab-${index}`} {...other}>
            {value === index && <Box sx={{ pt: 3 }}>{children}</Box>}
        </div>
    );
};

TabPanel.propTypes = {
    children: PropTypes.node,
    value: PropTypes.number.isRequired,
    index: PropTypes.number.isRequired
};

const Report = () => {
    const [value, setValue] = useState(0);
    const { surveyToken } = useParams();
    const [reportSummary, setReportSummary] = useState();
    const [responses, setResponses] = useState();
    const [survey, setSurvey] = useState({
        survey_title: '',
        survey_description: '',
        quota: 0,
        start_date: null,
        end_date: null,
        survey_type: '',
        survey_status: '',
        show_in_listing: true,
        meta: {
            save_as_template: false,
            public_survey: true,
            anon_survey: false
        }
    });

    const loadSurveyInfo = useCallback(async () => {
        const response = await SurveyService.show(surveyToken);
        setSurvey(response.data);
    }, [surveyToken]);

    useEffect(() => {
        loadSurveyInfo();
    }, [loadSurveyInfo]);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Box sx={{ flexGrow: 1, bgcolor: '#f8f9fa' }}>
            <Grid container spacing={2}>
                <Grid
                    xs={12}
                    sm={12}
                    md={12}
                    sx={{
                        background: '#fff',
                        borderTopLeftRadius: '10px',
                        borderBottomLeftRadius: '10px'
                    }}
                >
                    <Paper elevation={0} sx={{ borderRadius: '8px', overflow: 'hidden' }}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <Tabs
                                value={value}
                                onChange={handleChange}
                                variant="scrollable"
                                scrollButtons="auto"
                                aria-label="survey report tabs"
                            >
                                <StyledTab label="Summary" />
                                <StyledTab label="Detailed" />
                                <StyledTab label="All Responses" />
                                {survey.survey_type === 'Assessment' && <StyledTab label="Insights" />}
                                <GlobalModalHelpButton title="Report" content="https://kb.surveyflip.com/managing-responses/reports.html">
                                    <IconButton color="inherit">
                                        <HelpOutlineIcon />
                                    </IconButton>
                                </GlobalModalHelpButton>
                            </Tabs>
                        </Box>

                        <Box sx={{ p: 3 }}>
                            <TabPanel value={value} index={0}>
                                <Summary reportSummary={reportSummary} />
                            </TabPanel>
                            <TabPanel value={value} index={1}>
                                <DetailedReport responses={responses} responsesStarted={reportSummary && reportSummary.started} />
                            </TabPanel>
                            <TabPanel value={value} index={2}>
                                <ResponseSummary responses={responses} reportSummary={reportSummary} />
                            </TabPanel>
                            <TabPanel value={value} index={3}>
                                {survey.survey_type === 'Assessment' && (
                                    <ResponseInsights responses={responses} reportSummary={reportSummary} />
                                )}
                            </TabPanel>
                        </Box>
                    </Paper>
                </Grid>
            </Grid>
        </Box>
    );
};

export default Report;
