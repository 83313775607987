import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { Box, Typography, Grid, Button, Chip, List, ListItem, ListItemIcon, ListItemText, IconButton } from '@mui/material';
import PropTypes from 'prop-types';
import HelpIcon from '@mui/icons-material/Help';
import {
    AccessTime as ClockIcon,
    Group as UsersIcon,
    Lock as LockIcon,
    LockOpen as UnlockIcon,
    FiberManualRecord as StatusIcon,
    Add,
    FormatListBulleted
} from '@mui/icons-material';
import dayjs from 'dayjs';
import QuestionBranching from './components/QuestionBranching';
import SurveyViewEdit from './components/SurveyViewEdit';
import { useDispatch } from 'react-redux';
import { activeMainItem } from 'store/slices/menu';
import useGlobalModal from 'hooks/useGlobalModal';
import GlobalModalHelpButton from 'views/components/GlobalModalHelpButton';

const Overview = ({ survey, setValue, setSurvey }) => {
    const [open, setOpen] = useState(false);
    const { openGlobalModal } = useGlobalModal();
    const dispatch = useDispatch();

    const handleSidebarOpenClose = useCallback((value) => setOpen(value), []);
    const handleSurveyEditSubmit = useCallback(
        (updatedSurvey) => {
            setOpen(false);
            setSurvey(updatedSurvey);
        },
        [setSurvey]
    );

    // Memoize survey status color
    const surveyStatusColor = useMemo(() => {
        switch (survey?.survey_status) {
            case 'Live':
                return 'success';
            case 'Development':
                return 'warning';
            case 'finished':
                return 'primary';
            default:
                return 'error';
        }
    }, [survey]);

    useEffect(() => {
        dispatch(activeMainItem(['dashboard']));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
                <Grid
                    item
                    xs={12}
                    sx={{
                        background: '#fff',
                        borderRadius: '10px',
                        padding: '30px',
                        height: 'calc(100vh - 100px)'
                    }}
                >
                    <Grid container justifyContent="space-between" alignItems="center" spacing={2}>
                        <Grid item xs={12} md={10}>
                            <Typography variant="h2" component="h1" gutterBottom sx={{ lineHeight: 1.5 }}>
                                {survey?.survey_title}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={2} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <Button variant="outlined" color="secondary" onClick={() => handleSidebarOpenClose(true)}>
                                Edit Survey
                            </Button>
                        </Grid>
                    </Grid>

                    <Grid container spacing={3} sx={{ mb: 3 }}>
                        {/* Left Column */}
                        <Grid item xs={12} md={6}>
                            <List disablePadding>
                                <ListItem>
                                    <ListItemIcon>
                                        <StatusIcon color={surveyStatusColor} />
                                    </ListItemIcon>
                                    <ListItemText
                                        primary="Status"
                                        secondary={<Chip label={survey?.survey_status} color={surveyStatusColor} size="small" />}
                                    />
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon>
                                        <ClockIcon />
                                    </ListItemIcon>
                                    <ListItemText
                                        primary="Created"
                                        secondary={`${dayjs(survey?.created_at).format('MMM DD, YYYY hh:mm A')} (${
                                            survey?.meta?.timezone
                                        })`}
                                    />
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon>
                                        <UsersIcon />
                                    </ListItemIcon>
                                    <ListItemText
                                        primary="Quota Remaining"
                                        secondary={`${survey?.meta?.remaining_quota} out of ${survey?.quota}`}
                                    />
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon>
                                        <FormatListBulleted />
                                    </ListItemIcon>
                                    <ListItemText primary="Survey Type" secondary={<Chip label={survey?.survey_type} size="small" />} />
                                </ListItem>
                            </List>
                        </Grid>

                        {/* Right Column */}
                        <Grid item xs={12} md={6}>
                            <List disablePadding>
                                <ListItem>
                                    <ListItemIcon>
                                        {survey?.meta?.public_survey ? <UnlockIcon color="success" /> : <LockIcon color="error" />}
                                    </ListItemIcon>
                                    <ListItemText
                                        primary="Public Survey"
                                        secondary={
                                            <Chip
                                                label={survey?.meta?.public_survey ? 'Yes' : 'No'}
                                                color={survey?.meta?.public_survey ? 'success' : 'error'}
                                                size="small"
                                            />
                                        }
                                    />
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon>
                                        {survey?.meta?.anon_survey ? <UnlockIcon color="success" /> : <LockIcon color="error" />}
                                    </ListItemIcon>
                                    <ListItemText
                                        primary="Anonymous Survey"
                                        secondary={
                                            <Chip
                                                label={survey?.meta?.anon_survey ? 'Yes' : 'No'}
                                                color={survey?.meta?.anon_survey ? 'success' : 'error'}
                                                size="small"
                                            />
                                        }
                                    />
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon>
                                        <ClockIcon />
                                    </ListItemIcon>
                                    <ListItemText
                                        primary="Start Date"
                                        secondary={`${dayjs(survey?.start_date).format('MMM DD, YYYY hh:mm A')} (${
                                            survey?.meta?.timezone
                                        })`}
                                    />
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon>
                                        <ClockIcon />
                                    </ListItemIcon>
                                    <ListItemText
                                        primary="End Date"
                                        secondary={`${dayjs(survey?.end_date).format('MMM DD, YYYY hh:mm A')} (${survey?.meta?.timezone})`}
                                    />
                                </ListItem>
                            </List>
                        </Grid>
                    </Grid>

                    {/* Bottom Actions */}
                    <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                        <Button
                            variant="contained"
                            color="primary"
                            startIcon={<Add />}
                            onClick={() => setValue(1)}
                            disabled={survey?.survey_status === 'Live'}
                        >
                            Add Question
                        </Button>
                        <QuestionBranching survey={survey} variant="contained" />
                        <GlobalModalHelpButton
                            title="How branching works"
                            content="https://kb.surveyflip.com/create-survey-content/question-branching.html"
                        />
                    </Box>
                </Grid>
            </Grid>

            {open && (
                <SurveyViewEdit
                    open={open}
                    handleSidebarOpenClose={handleSidebarOpenClose}
                    survey={survey}
                    setOpen={setOpen}
                    handleSurveyEditSubmit={handleSurveyEditSubmit}
                />
            )}
        </Box>
    );
};

Overview.propTypes = {
    survey: PropTypes.object.isRequired,
    setSurvey: PropTypes.func.isRequired,
    setValue: PropTypes.func.isRequired
};

export default Overview;
