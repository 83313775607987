import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Box, Typography, Alert, CircularProgress, useTheme } from '@mui/material';
import { DataGrid, gridClasses } from '@mui/x-data-grid';
import { alpha } from '@mui/material/styles';

const ListView = ({
    rows,
    pageSize,
    onPageSizeChange,
    columns,
    paginationProp,
    totalRowCount,
    handlePageChange,
    handleSortChange,
    handleFilterChange,
    isLoading,
    title
}) => {
    const theme = useTheme();

    const NoRowsOverlay = () => (
        <Box display="flex" alignItems="center" justifyContent="center" height="100%">
            <Alert severity="info" sx={{ backgroundColor: 'transparent' }}>
                No survey found.
            </Alert>
        </Box>
    );

    const CustomLoadingOverlay = () => (
        <Box display="flex" alignItems="center" justifyContent="center" height="100%">
            <CircularProgress />
        </Box>
    );

    const getRowSpacing = React.useCallback(
        (params) => ({
            top: params.isFirstVisible ? 0 : 8,
            bottom: params.isLastVisible ? 0 : 8
        }),
        []
    );

    return (
        <Box sx={{ mt: 2 }}>
            {title && (
                <Typography variant="h6" component="h2" sx={{ mb: 2 }}>
                    {title}
                </Typography>
            )}
            <DataGrid
                rows={rows}
                columns={columns.current}
                pagination
                paginationMode="server"
                sortingMode="server"
                filterMode="server"
                autoHeight
                pageSize={pageSize}
                onPageSizeChange={onPageSizeChange}
                rowsPerPageOptions={[25, 50, 100, 500]}
                page={paginationProp.page}
                rowCount={totalRowCount}
                onPageChange={handlePageChange}
                onSortModelChange={handleSortChange}
                onFilterModelChange={handleFilterChange}
                sortingOrder={['desc', 'asc']}
                loading={isLoading}
                disableColumnMenu
                disableSelectionOnClick
                components={{
                    NoRowsOverlay,
                    LoadingOverlay: CustomLoadingOverlay
                }}
                hideFooterPagination={totalRowCount <= rows?.length}
                getRowSpacing={getRowSpacing}
                sx={{
                    border: 'none',
                    borderRadius: 'none',
                    backgroundColor: 'transparent',
                    height: '100%',
                    '& .MuiDataGrid-columnHeaders': {
                        backgroundColor: 'transparent',
                        color: theme.palette.primary.main,
                        fontWeight: 'bold',
                        border: 'none'
                    },
                    '& .MuiDataGrid-cell': {
                        backgroundColor: 'transparent'
                    },
                    '&, [class^=MuiDataGrid]': { border: 'none', borderRadius: 'none' },
                    '& .MuiDataGrid-row': {
                        borderRadius: '10px',
                        background: 'rgba(97, 97, 97, 0.09)',
                        textDecoration: 'none'
                    },

                    '& .MuiDataGrid-row a': {
                        textDecoration: 'none'
                    },
                    [`& .${gridClasses.row}.even`]: {
                        backgroundColor: alpha(theme.palette.background.default, 0.4)
                    },
                    [`& .${gridClasses.row}:hover`]: {
                        backgroundColor: alpha(theme.palette.primary.main, 0.08)
                    },
                    '& .MuiDataGrid-columnSeparator': {
                        display: 'none'
                    },
                    '& .MuiDataGrid-menuIcon': {
                        display: 'none'
                    },
                    '& .MuiDataGrid-cell:focus, & .MuiDataGrid-cell:focus-within': {
                        outline: 'none'
                    },
                    '& .MuiDataGrid-columnHeader:focus, & .MuiDataGrid-columnHeader:focus-within': {
                        outline: 'none'
                    }
                }}
            />
        </Box>
    );
};

ListView.propTypes = {
    isLoading: PropTypes.bool,
    rows: PropTypes.array.isRequired,
    pageSize: PropTypes.number.isRequired,
    onPageSizeChange: PropTypes.func.isRequired,
    columns: PropTypes.object.isRequired,
    paginationProp: PropTypes.object.isRequired,
    totalRowCount: PropTypes.number.isRequired,
    handlePageChange: PropTypes.func.isRequired,
    handleSortChange: PropTypes.func.isRequired,
    handleFilterChange: PropTypes.func.isRequired,
    title: PropTypes.string
};

export default ListView;
