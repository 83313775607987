import { Link as RouterLink } from 'react-router-dom';

// material-ui
import { Link } from '@mui/material';

// project imports
import { DASHBOARD_PATH } from 'config';
import logo from 'assets/images/surveyFlip-logo-text.svg';

// ==============================|| MAIN LOGO ||============================== //

const LogoSection = () => (
    <Link component={RouterLink} to={DASHBOARD_PATH}>
        <img src={logo} alt="Survey Flip" width="180" />
    </Link>
);

export default LogoSection;
