import React, { useState } from 'react';
import {
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Typography,
    IconButton,
    Slider,
    Paper,
    Grid,
    Box,
    Alert
} from '@mui/material';
import { Close as CloseIcon, ShoppingCart } from '@mui/icons-material';
import PropTypes from 'prop-types';
import Checkout from '../upgrade';
import { IconX } from '@tabler/icons';

const BuyMoreModal = ({ responseCount, handleSliderChange, openModal, price, handlePurchase, handleCloseModal }) => {
    const [showCheckout, setShowCheckout] = useState(false);
    const [paymentStatus, setPaymentStatus] = useState(null);

    const handlePurchaseClick = () => {
        setShowCheckout(true);
    };

    const handleBackToSlider = () => {
        setShowCheckout(false);
        setPaymentStatus(null);
    };

    const handlePaymentSuccess = (paymentIntent) => {
        setPaymentStatus({ type: 'success', message: 'Payment successful!' });
        handlePurchase(responseCount, paymentIntent);
    };

    const handlePaymentError = (errorMessage) => {
        setPaymentStatus({ type: 'error', message: errorMessage });
    };

    return (
        <Dialog
            open={openModal}
            onClose={handleCloseModal}
            fullWidth
            maxWidth="lg"
            PaperProps={{
                style: { borderRadius: 16 }
            }}
        >
            <DialogTitle>
                <Box display="flex" alignItems="center" justifyContent="space-between">
                    <Typography variant="h2" component="div" sx={{ fontWeight: 'bold' }}>
                        {showCheckout ? 'Complete Purchase' : 'Buy More Responses'}
                    </Typography>
                    <IconButton edge="end" color="inherit" onClick={handleCloseModal} aria-label="close">
                        <CloseIcon />
                    </IconButton>
                </Box>
            </DialogTitle>
            <DialogContent>
                {paymentStatus ? (
                    <Alert severity={paymentStatus.type} sx={{ mb: 2 }}>
                        {paymentStatus.message}
                    </Alert>
                ) : (
                    <>
                        {!showCheckout ? (
                            <>
                                <Typography variant="h4" gutterBottom sx={{ mb: 2 }}>
                                    Adjust the slider to select the number of responses you would like to purchase:
                                </Typography>
                                <Slider
                                    value={responseCount}
                                    onChange={handleSliderChange}
                                    aria-labelledby="response-slider"
                                    valueLabelDisplay="auto"
                                    step={100}
                                    marks
                                    min={100}
                                    max={5000}
                                    sx={{ mt: 4, mb: 2 }}
                                />
                                <Paper elevation={3} sx={{ p: 2, borderRadius: 2, bgcolor: 'background.default' }}>
                                    <Grid container justifyContent="space-between" alignItems="center">
                                        <Grid item>
                                            <Typography variant="h5" color="primary">
                                                Responses: {responseCount}
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography variant="h5" color="secondary">
                                                Price: ${price}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </>
                        ) : (
                            <Checkout onPaymentSuccess={handlePaymentSuccess} onPaymentError={handlePaymentError} amount={price} />
                        )}
                    </>
                )}
            </DialogContent>
            {!paymentStatus && (
                <DialogActions sx={{ p: 2 }}>
                    {!showCheckout ? (
                        <Button
                            onClick={handlePurchaseClick}
                            variant="contained"
                            color="primary"
                            endIcon={<ShoppingCart />}
                            size="large"
                            sx={{ borderRadius: 20, px: 3 }}
                        >
                            Purchase
                        </Button>
                    ) : (
                        <Button onClick={handleBackToSlider} variant="outlined" color="inherit">
                            Back
                        </Button>
                    )}
                </DialogActions>
            )}
        </Dialog>
    );
};

BuyMoreModal.propTypes = {
    responseCount: PropTypes.number.isRequired,
    handleSliderChange: PropTypes.func.isRequired,
    openModal: PropTypes.bool.isRequired,
    price: PropTypes.number.isRequired,
    handlePurchase: PropTypes.func.isRequired,
    handleCloseModal: PropTypes.func.isRequired
};

export default BuyMoreModal;
