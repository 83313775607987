/* eslint-disable react/self-closing-comp */
import {
    Button,
    Card,
    CardContent,
    Dialog,
    DialogActions,
    DialogContent,
    Grid,
    ListItem,
    ListItemIcon,
    ListItemText,
    Typography
} from '@mui/material';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import iconData from './icons';
import useOnePageSurveyStore from 'zustand_store/useOnePageSurveyStore';
import useQuestionController from './controllers/QuestionController';
import { useParams } from 'react-router-dom';
import useQuestionSubmitHandlerController from './controllers/QuestionSubmitHandlerController';
import snackcaseToPascalcase from 'utils/helperFunctions';
import GlobalModalHelpButton from 'views/components/GlobalModalHelpButton';

// ==============================|| Main Template ||============================== //

const QuestionTypes = ({ onClose, open, formik }) => {
    // const [questionTypes, setQuestionTypes] = useState([]);
    const questionTypes = useOnePageSurveyStore((state) => state.questionTypes);
    const { MessageQuestionSubmitHandler } = useQuestionSubmitHandlerController();
    const { HandleQuestionAdd } = useQuestionController();
    const { surveyToken } = useParams();
    const { SubmitHandlerFunctionMap } = useQuestionSubmitHandlerController();

    const renderQuestionTypeIcon = (questionIdentifier) => {
        const Component = iconData.find((icon) => icon.name === questionIdentifier).icon;
        return <Component />;
    };

    const handleQuestionTypeChange = async (identifier) => {
        const formikCurrentValue = formik.values;
        HandleQuestionAdd(identifier, surveyToken);
        onClose();

        if (snackcaseToPascalcase(formik.values?.question_type?.identifier) === 'MessageQuestion') {
            await MessageQuestionSubmitHandler(formikCurrentValue, formik);
        } else {
            // This will be dynamic `{identifier}SubmitHandler`
            await SubmitHandlerFunctionMap[`${snackcaseToPascalcase(formik.values?.question_type?.identifier)}SubmitHandler`](
                formikCurrentValue
            );
        }
    };

    return (
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth
            maxWidth="lg"
        >
            <DialogContent>
                <Card>
                    <CardContent>
                        <Typography gutterBottom variant="h2" component="div">
                            Select a Question Type
                            <GlobalModalHelpButton
                                title="Select a Question Type"
                                content="https://kb.surveyflip.com/create-survey-content/create-question.html"
                            />
                        </Typography>

                        <Grid container mt={1} mb={2}>
                            {questionTypes
                                ?.filter((type) => type.is_active)
                                ?.map((value) => (
                                    <Grid
                                        item
                                        xs={12}
                                        sm={6}
                                        lg={3}
                                        md={4}
                                        mt={2}
                                        key={value.id}
                                        sx={{
                                            cursor: 'pointer',
                                            borderRadius: '5px',
                                            '&:hover': {
                                                background: 'rgba(101, 108, 133, 0.1)'
                                            }
                                        }}
                                    >
                                        <ListItem onClick={() => handleQuestionTypeChange(value.identifier)}>
                                            <ListItemIcon>{renderQuestionTypeIcon(value.identifier)}</ListItemIcon>
                                            <ListItemText primary={value.name} />
                                        </ListItem>
                                    </Grid>
                                ))}
                        </Grid>
                    </CardContent>
                </Card>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} autoFocus disableRipple>
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
};

QuestionTypes.propTypes = {
    onClose: PropTypes.func,
    open: PropTypes.bool,
    formik: PropTypes.any
};

export default QuestionTypes;
