import React from 'react';
import PropTypes from 'prop-types';
import { IconButton } from '@mui/material';
import HelpIcon from '@mui/icons-material/Help';
import useGlobalModal from 'hooks/useGlobalModal';

const GlobalModalHelpButton = ({ title, content, button = <HelpIcon /> }) => {
    const { openGlobalModal } = useGlobalModal();

    const handleClick = () => {
        openGlobalModal({ title, content });
    };

    return (
        <IconButton color="primary" onClick={handleClick} sx={{ pl: '0px' }}>
            {button}
        </IconButton>
    );
};

GlobalModalHelpButton.propTypes = {
    title: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
    button: PropTypes.node
};

export default GlobalModalHelpButton;
