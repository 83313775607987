import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Card, CardActions, CardContent, IconButton, List, Typography } from '@mui/material';
import SurveyService from 'services/survey.service';
import Item from '../Item';
import { useSnackbar } from 'notistack';
import { Check } from '@mui/icons-material';

const QuestionThemes = ({ survey, setSurvey }) => {
    const [themes, setThemes] = useState([]);
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        const fetchThemes = async () => {
            try {
                const response = await SurveyService.getAllSurveyThemes();
                setThemes(response.data);
            } catch (error) {
                console.error('Error fetching survey themes:', error);
            }
        };

        fetchThemes();
    }, []);

    const handleThemeSelect = async (themeId) => {
        try {
            const { data } = await SurveyService.updateSurveyTheme(survey, survey.survey_token, themeId);
            setSurvey({ ...survey, ...data });
            enqueueSnackbar('Survey Theme Updated!', {
                variant: 'success',
                autoHideDuration: 3000
            });
        } catch (error) {
            console.error('Error updating survey theme:', error);
        }
    };

    const parseColors = (colorsData) => {
        if (typeof colorsData === 'string') {
            try {
                return JSON.parse(colorsData);
            } catch (error) {
                console.error('Error parsing colors:', error);
                return {};
            }
        }
        return colorsData || {};
    };

    return (
        <Item style={{ border: '0' }}>
            <Typography variant="h5" align="left">
                Question Themes
            </Typography>
            <List>
                {themes?.length > 0 &&
                    themes?.map((theme) => {
                        const colors = parseColors(theme.colors);
                        const isSelected = Number(survey.survey_theme?.id) === theme.id;

                        return (
                            <Card
                                sx={{
                                    margin: '10px 0',
                                    border: '1px solid #c3c3c3',
                                    cursor: 'pointer'
                                }}
                                key={theme.id}
                                onClick={() => handleThemeSelect(theme.id)}
                            >
                                <CardContent
                                    sx={{
                                        background: colors.backgroundColor || '#ffffff',
                                        color: colors.textColor || '#000000',
                                        padding: '10px',
                                        borderBottom: '1px solid #c3c3c3'
                                    }}
                                >
                                    <Typography gutterBottom>Question</Typography>
                                    <Typography variant="h5" component="div" sx={{ color: colors.textColor || '#000000' }}>
                                        Answer
                                    </Typography>
                                </CardContent>
                                <CardActions
                                    sx={{
                                        padding: '10px',
                                        background: colors.boxColor || '#dddddd',
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center'
                                    }}
                                >
                                    <Button size="small" sx={{ color: colors.textColor || '#000000' }}>
                                        {theme.name}
                                    </Button>
                                    {isSelected && (
                                        <IconButton
                                            size="small"
                                            sx={{
                                                background: 'green',
                                                borderRadius: '50%',
                                                padding: '4px',
                                                width: '24px',
                                                height: '24px',
                                                '&:hover': {
                                                    background: 'green'
                                                }
                                            }}
                                        >
                                            <Check
                                                sx={{
                                                    color: 'white',
                                                    fontSize: '16px'
                                                }}
                                            />
                                        </IconButton>
                                    )}
                                </CardActions>
                            </Card>
                        );
                    })}
            </List>
        </Item>
    );
};

QuestionThemes.propTypes = {
    survey: PropTypes.shape({
        survey_token: PropTypes.string.isRequired,
        survey_theme: PropTypes.shape({
            id: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        })
    }).isRequired,
    setSurvey: PropTypes.func.isRequired
};

export default QuestionThemes;
